import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import cl from "./Sorting.module.scss";

const Sorting: React.FC<any> = ({
  sortDevice,
  setSortDevice,
  sortSystem,
  setSortSystem,
}) => {
  return (
    <Box className={cl.sorting}>
      <Typography sx={{ fontSize: "12px" }}>SORT BY:</Typography>
      <FormControl sx={{ minWidth: 120 }} size="small">
        <InputLabel id="device" sx={{ fontSize: 12 }}>
          DEVICE
        </InputLabel>
        <Select
          labelId="device"
          id="device"
          value={sortDevice}
          label="DEVICE"
          onChange={(e) => setSortDevice(e.target.value)}
          sx={{ fontSize: "12px" }}
          className={cl.select}
        >
          <MenuItem value={"all"}>ALL</MenuItem>
          <MenuItem value={"pc"}>PC STATION</MenuItem>
          <MenuItem value={"laptop"}>LAPTOP</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ minWidth: 120 }} size="small">
        <InputLabel id="system" sx={{ fontSize: "12px" }}>
          SYSTEM
        </InputLabel>
        <Select
          labelId="system"
          id="system"
          value={sortSystem}
          label="DEVICE"
          onChange={(e) => setSortSystem(e.target.value)}
          sx={{ fontSize: "12px" }}
        >
          <MenuItem value={"all"}>ALL</MenuItem>
          <MenuItem value={"mac"}>MAC OS</MenuItem>
          <MenuItem value={"windows"}>WINDOWS</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default Sorting;
