import { Box } from "@mui/material";
import React from "react";
import YouTube from "react-youtube";
import cl from "./VideoPreview.module.scss";


const VideoPreview: React.FC<any> = ({ link }) => {
  // const videoId = link?.split("v=")[1]?.split("&")[0]; // Извлечение ID видео
  // console.log(videoId);

  const regex = /(?<=\/)([^\?]+)/;
  const videoId = link.split("/")[3]?.split("?")[0];

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      disablekb: 1, // Отключение клавиатурного управления
      controls: 0, // Отключение элементов управления плеером
      showinfo: 1, // Отключение информации о видео
      rel: 1, // Отключение связанных видео
      modestbranding: 1, // Удаление логотипа YouTube
      //  ... и другие параметры плеера
    },
  };

  return (
    <YouTube
      videoId={videoId}
      opts={opts}
      onStateChange={(event: any) => {
        if (event.data === 0) {
        }
      }}
    />
  );
};

export default VideoPreview;

const Youtube = () => {
  const youtubeLink = "https://www.youtube.com/watch?v=dQw4w9WgXcQ"; // Пример ссылки
  const videoId = youtubeLink.split("v=")[1].split("&")[0]; // Извлечение ID видео

  return (
    <Box className={cl.video}>
      <VideoPreview videoId={videoId} />
    </Box>
  );
};
