import axios from "axios";

const apiUrl = process.env.REACT_APP_API;

const ajax_get = (url: string, func:any) => {
  let items: any = [];
  axios
    .get(apiUrl + url)
    .then((res) => {
      if (res) {
        items = res.data;
        func(items);
      }
    })
    .catch((e) => {
      console.log(e);
      return;
    });
};

export default ajax_get;
