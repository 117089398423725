import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import cl from "./Profile.module.scss";
import RatingCard from "../rating/ratingCard/RatingCard";
import { card_pc } from "../../utils/types/card";
import ajax_get from "../../../controllers/rating/AjaxGet";
//@ts-ignore
const tg = window.Telegram?.WebApp;

const Profile = () => {
  const [item, setItem] = useState<card_pc | null>(null);

  useEffect(() => {
    const id = tg?.initDataUnsafe?.user?.id;
    if (id) {
      const res = (data:any) => {
        setItem(data);
      }
    ajax_get(`?chat_id=${id}`, res);
    }
  }, []);

  return (
    <Box className={cl.profile}>
      <Box className={cl.profile_title}>
        <Typography variant="h5">Profile</Typography>
      </Box>
      <Typography variant="h6">My configuration</Typography>
      {item ? (
        <RatingCard item={item} />
      ) : (
        <Box>
          <Typography >No result found</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Profile;
