import {
  Box,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import cl from "./RatingView.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import RatingCard from "./ratingCard/RatingCard";
import { card_pc } from "../../utils/types/card";
import axios from "axios";
import Sorting from "./sorting/Sorting";

const RatingView: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [sortDevice, setSortDevice] = useState<string>("all");
  const [sortSystem, setSortSystem] = useState<string>("all");
  const [items, setItems] = useState<card_pc[] | null>(null);
  const [step, setStep] = useState<number>(0);
  const [filteredItems, setFilteredItems] = useState<card_pc[] | null>(items);
  const apiUrl = process.env.REACT_APP_API;

  const getItems = (device: string, step: number) => {
    let laptops: any = [];
    setLoading(true);
    axios
      .get(apiUrl + `?${device}=1&step=${step}`)
      .then((res) => {
        if (res) {
          let data = res.data;
          setLoading(false);
          setItems(data);
          setFilteredItems(data);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        return;
      });
    return laptops;
  };

  useEffect(() => {
    getItems(sortDevice, step);
  }, [sortDevice, step]);

  useEffect(() => {
    if (sortSystem === "all") {
      setFilteredItems(items);
    } else if (sortSystem === "mac") {
      const filtered = items?.filter(
        (elem) => elem.name && elem.name.toLowerCase().includes("mac")
      );
      if (filtered) {
        setFilteredItems(filtered);
      }
    } else {
      const filtered = items?.filter(
        (elem) => elem.name && !elem.name.toLowerCase().includes("mac")
      );
      if (filtered) {
        setFilteredItems(filtered);
      }
    }
  }, [sortSystem]);

  return (
    <Box
      className={cl.rating_view}
      sx={{ justifyContent: loading ? "center" : "flex-start" }}
    >
      <Sorting
        sortDevice={sortDevice}
        setSortDevice={setSortDevice}
        sortSystem={sortSystem}
        setSortSystem={setSortSystem}
      />
      <Box className={cl.rating_list}>
        {!loading ? (
          filteredItems ? (
            filteredItems.map(
              (item: card_pc) =>
                item.time_render &&
                item.time_render < 9999 &&
                item.ae_version &&
                item.cpu &&
                item.chat_id !== 1378371551 && (
                  <RatingCard item={item} key={item.id} />
                )
            )
          ) : (
            <Box>
              <Typography variant="h6">No results found</Typography>
            </Box>
          )
        ) : (
          <CircularProgress />
        )}
        {/* {!loading && filteredItems && (
          <Box>
            <Button onClick={() => setStep((prev) => prev + 1)}>
              Показать еще
            </Button>
          </Box>
        )} */}
      </Box>
    </Box>
  );
};

export default RatingView;
