import { Box, Typography } from "@mui/material";
import React from "react";
import cl from "./Finish.module.scss";
import space from "../../../../assets/img/space.svg";
const Finish = () => {
  return (
    <Box className={cl.finish}>
        <img src={space} alt="space" />
      <Typography variant="h5" paragraph>Thank YOU!</Typography>
      <Typography>Your results have been added to the general list</Typography>
    </Box>
  );
};

export default Finish;
