import React, { useEffect, useState } from "react";
import cl from "./RatingCard.module.scss";
import { Box, Chip, Grid, LinearProgress, Typography } from "@mui/material";
import { card_pc } from "../../../utils/types/card";
import macbook_img from "../../../../assets/img/mac_pro.png";
import mac_studio_img from "../../../../assets/img/mac_studio.png";
import pc_windows from "../../../../assets/img/pc_windows.png";
import laptop_windows from "../../../../assets/img/laptop_windows.png";
import WindowsIcon from "../../../icons/WindowsIcon";
import MacIcon from "../../../icons/MacIcon";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VideoPreview from "./videoPreview/VideoPreview";
import FireIcon from "../../../icons/FireIcon";

interface CardPcProps {
  item: card_pc;
}

const RatingCard: React.FC<CardPcProps> = ({ item }) => {
  const [image, setImage] = useState<string>("");
  const [os, setOs] = useState<"mac" | "windows">("mac");
  const [formattedTime, setFormattedTime] = useState<string>("");
  const [formattedTime3D, setFormattedTime3D] = useState<string>("");

  function decimalToTime(time: number) {
    const minutes = Math.floor((time * 60) / 60);
    const seconds = Math.floor((time * 60) % 60);

    return `${minutes} MIN / ${seconds} SEC`;
  }

  useEffect(() => {
    if (item.time_render) {
      let time = decimalToTime(item.time_render);
      setFormattedTime(time);
    }

    if (item.three_d) {
      let time = decimalToTime(item.three_d);
      setFormattedTime3D(time);
    }
    let name = item.name?.toLowerCase();

    if (
      name?.includes("macbook") ||
      (name?.includes("mac") && name?.includes("pro"))
    ) {
      setImage(macbook_img);
      setOs("mac");
    } else if (name?.includes("studio") && item.device === "🖥") {
      setImage(mac_studio_img);
      setOs("mac");
    } else if (item.device === "🖥" && !name?.includes("mac")) {
      setImage(pc_windows);
      setOs("windows");
    } else {
      setOs("windows");
      setImage(laptop_windows);
    }

  }, [item]);

  const normalise = (value: number) => ((value - 60) * 100) / (4 - 60);

  return (
    <Box className={cl.card} key={item.id}>
      <Grid container className={cl.card_main}>
        <Grid item xs={5} className={cl.card_main__img}>
          <img src={image} alt="mac" />
          <Box className={cl.card_main__img__device}>
            {os === "mac" ? <MacIcon /> : <WindowsIcon />}
          </Box>
        </Grid>
        <Grid item xs={7} className={cl.card_main__description}>
          <Typography variant="h6">
            {item.name ? item.name : "No name"}
          </Typography>
          {item.cpu && (
            <Typography>
              {item.cpu} {item.videocard && " | " + item.videocard}
            </Typography>
          )}
          {item.ram && <Typography>{item.ram} GB</Typography>}
        </Grid>
      </Grid>

      <Box>
        <LinearProgress
          className={cl.Progress}
          variant="determinate"
          value={normalise(item.time_render)}
        />
      </Box>

      <Box className={cl.card_result}>
        <Typography>SHOCK BENCH</Typography>
        <Typography>{formattedTime}</Typography>
      </Box>

      {item.three_d && (
        <Box>
          <Box>
            <LinearProgress
              className={cl.Three_d}
              variant="determinate"
              value={normalise(item.three_d)}
            />
          </Box>
          <Box className={cl.card_result}>
            <Typography>3D BENCH</Typography>
            <Typography>{formattedTime3D}</Typography>
          </Box>
        </Box>
      )}

      <Grid container>
        <Grid item xs={3}>
          <Chip
            color="error"
            label={"AE " + item.ae_version + ".X"}
            className={cl.chip_version}
          />
        </Grid>
        {item.time_render <= 6.01 && (
          <Grid item xs={3}>
            <Chip
              color="error"
              label="BEST"
              icon={<FireIcon />}
              className={cl.chip_best}
            />
          </Grid>
        )}

        {item.watch_link && (
          <Grid item xs={6}>
            <a href={item.watch_link} target="_blank">
              <Chip
                onClick={() => console.log("link")}
                label="WATCH REVIEW"
                icon={<VisibilityIcon />}
                size="medium"
                className={cl.chip}
              />
            </a>
          </Grid>
        )}
      </Grid>
      {item.watch_link && (
        <Box className={cl.youtube} mt={2}>
          <VideoPreview link={item.watch_link} />
        </Box>
      )}
    </Box>
  );
};

export default RatingCard;
