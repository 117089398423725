const text_about = {
    en: {
      desc_1: `is a modern and technological benchmark project
     created for After Effects. Our project uses scenes to test the GPU,
     CPU, SSD and RAM speed, processor multi-threading, amount of RAM and
     disk cache size.`,
      desc_2: `METHOD:`,
      desc_3: `The testing process occurs in three stages:`,
      desc_3_1: `Pre-rendering of the
    animation sequence in PNG format with an alpha channel. `,
      desc_3_2: `Automatic
    import of the rendered sequence with replacement of the same scene.`,
      desc_3_3: `Rendering the main scene, from which you can determine the final
    rendering time.`,
      desc_4: `The project consists of 6 different and unique scenes created
    specifically for testing.`,
      desc_5: `Before you start testing, you need to check the initial settings of
    your After Effect.`,
      desc_6: `Initial settings After Effects`,
      desc_6_1: `Set minimum cache size: 80 GB`,
      desc_6_2: `Set the minimum value for the use of RAM for other applications
    in the "memory and performance" section`,
      desc_6_3: `Enable multi-frame rendering and minimum value for reserved for
    other applications`,
      desc_6_4: `In the project settings, select the GPU renderer suitable for
    your platform: Mac OS - OpenCL/Metal, Windows - OpenCL/CUDA`,
        
    },
  
    ru: {
      desc_1: `Это современный и технологический эталонный проект, созданный для After Effects.
  `,
      desc_2: `Наш проект использует сцены для тестирования скорости GPU, CPU, SSD и RAM, многопоточности процессора, объема оперативной памяти и размера кэша диска.
      `,
      desc_3: `Процесс тестирования проходит в три этапа:`,
      desc_3_1: `Предварительный рендеринг последовательности анимации в формате PNG с альфа-каналом.
      `,
      desc_3_2: `Автоматический импорт отрисованной последовательности с заменой той же сцены.
      `,
      desc_3_3: `Рендеринг главной сцены, из которой вы можете определить окончательное время рендеринга.
      `,
      desc_4: `Проект состоит из 6 различных и уникальных сцен, созданных специально для тестирования.
      `,
      desc_5: `Прежде чем начать тестирование, вам необходимо проверить начальные настройки After Effect.
      `,
      desc_6: `Начальные настройки After Effects
      `,
      desc_6_1: `Установить минимальный размер кэша: 80 ГБ
      `,
      desc_6_2: `Установите минимальное значение для использования оперативной памяти для других приложений в разделе "Memory & Perfomance"
      `,
      desc_6_3: `Включить многокадровый рендеринг и минимальное значение для зарезервированного для других приложений
      `,
      desc_6_4: `В настройках проекта выберите рендеринг GPU, подходящий для вашей платформы: Mac OS - OpenCL/Metal, Windows - OpenCL/CUDA`,
    }
}

export default text_about;