import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import cl from "./Header.module.scss";

const Header: React.FC<any> = ({text_about, setLocal}) => {
  const [lang, setLang] = useState<string | "en" | "ru">("en");

  const handleChangeLang = (text: string) => {
    setLang(text);
    if (text === "en") {
      setLocal(text_about.en);
    } else {
      setLocal(text_about.ru);
    }
  };
  return (
    <Box className={cl.header}>
    <Box className={cl.header__text}>
      <Typography>AE SHOCK BENCH</Typography>
      <span>ABOUT</span>
    </Box>
    <Box className={cl.buttons}>
      <button
        type="button"
        className={lang == "en" ? cl.active_button : cl.button}
        onClick={() => handleChangeLang("en")}
      >
        EN
      </button>
      <button
        type="button"
        className={lang == "ru" ? cl.active_button : cl.button}
        onClick={() => handleChangeLang("ru")}
      >
        RU
      </button>
    </Box>
  </Box>
  )
}

export default Header
